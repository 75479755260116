<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <!-- Modal -->
    <trac-modal v-if="deleteModal" @close="deleteModal = false">
      <div v-if="categoryDeleted" class="mx-20 my-10 text-center">
        <div class="flex-column">
          <img class="mx-auto mb-8" src="../../assets/svg/trash.svg" />
          <span class="font-semibold text-2xl"
            >You have successfully deleted
            <span class="capitalize">{{ category.name }}</span></span
          >
        </div>
      </div>
      <div v-else class="mx-10 my-8 text-center">
        <span class="font-semibold text-2xl"
          >Are you sure you want to delete
          <span class="capitalize">{{ category.name }}</span
          >?</span
        >
        <div class="flex flex-row w-8/12 justify-between mx-auto mt-16">
          <trac-button variant="outline" @button-clicked="deleteModal = false"
            >Cancel
          </trac-button>
          <trac-button
            class="text-secondaryRed border-secondaryRed"
            variant="outline"
            @button-clicked="deleteCategory"
            >Delete
          </trac-button>
        </div>
      </div>
    </trac-modal>
    <main class="md:ml-12 pr-4 md:pr-0" v-if="products">
      <div class="">
        <trac-back-button>Categories</trac-back-button>
        <div class="md:flex justify-between items-end md:py-4">
          <div class="flex-column mt-12">
            <h2>Category</h2>
            <h3 class="font-bold my-4 text-2xl capitalize">
              {{ category.name }}
            </h3>
          </div>
          <div class="md:flex items-baseline">
            <trac-button
              v-if="
                permissionsModules[4].parent.permissionsForUser.editCategory
              "
              variant="outline"
              class="uppercase w-full"
              @button-clicked="gotoEditCategory"
              >Edit Category</trac-button
            >
            <trac-button
              v-if="
                permissionsModules[4].parent.permissionsForUser.deleteCategory
              "
              @button-clicked="showDeleteModal"
              variant="outline"
              class="
                uppercase
                text-secondaryRed
                border-secondaryRed
                md:ml-5
                w-full
                my-4
                md:my-0
              "
              >Delete Category
            </trac-button>
          </div>
        </div>
      </div>
      <!--  -->
      <!-- <div class="grid md:flex flex-row items-end justify-between mb-8">
        <div class="flex-column mt-12 ">
          <h2>Category</h2>
          <h3 class="font-bold mt-4 text-2xl capitalize">
            {{ category.name }}
          </h3>
        </div>
        <div class="flex-column">
          <div class="flex flex-col md:flex-row">
            <trac-button
            v-if="permissionsModules[4].parent.permissionsForUser.editCategory"
              variant="outline"
              class="uppercase"
              @button-clicked="gotoEditCategory"
              >Edit Category</trac-button
            >
            <trac-button
            v-if="permissionsModules[4].parent.permissionsForUser.deleteCategory"
              @button-clicked="showDeleteModal"
              variant="outline"
              class="uppercase text-secondaryRed border-secondaryRed md:ml-5 w-full"
              >Delete Category
            </trac-button>
          </div>
        </div>
      </div> -->
      <div class="bg-white rounded-lg overflow-hidden big-shadow">
        <div class="mt-8 mb-5 mx-8 justify-between flex flex-row">
          <div class="flex flex-row items-center">
            <div class="flex flex-row items-center">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="8.16267"
                  cy="8.16267"
                  r="7.16267"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.116 17.116L13.2213 13.2213"
                  stroke="#253B95"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <label class="ml-3 text-xs font-bold text-primaryBlue"
                >SEARCH</label
              >
            </div>
          </div>
        </div>
        <div class="mx-8 mb-8 overflow-scroll pb-6">
          <table class="table-fixed md:w-full">
            <thead class="bg-accentLight">
              <tr class="">
                <th
                  class="text-center text-xs py-5 whitespace-no-wrap px-10 pl-8"
                >
                  ITEM
                </th>
                <th class="text-center text-xs py-5 whitespace-no-wrap px-10">
                  SKU
                </th>
                <th class="text-center text-xs py-5 whitespace-no-wrap px-10">
                  QTY IN STOCK
                </th>
                <th class="text-center text-xs py-5 whitespace-no-wrap px-10">
                  PRICE
                </th>
              </tr>
            </thead>
            <tbody  class="">
              <tr
                :class="index % 2 === 0 ? '' : 'bg-gray-100'"
                v-for="(product, index) in productsUnderCategory"
                class="cursor-pointer"
                :key="index"
              >
                <td
                  class="
                    text-xs text-left
                    px-10
                    font-medium
                    py-3
                    capitalize
                    pl-8
                    whitespace-no-wrap
                  "
                >
                  {{ product.title }}
                </td>
                <td class="text-xs text-center px-10 font-medium py-3">
                  {{ product.barcode }}
                </td>
                <td
                  class="text-xs text-center px-10 font-medium py-3 capitalize"
                >
                  {{ product.stock }}
                </td>
                <td
                  class="
                    text-xs text-left
                    px-10
                    font-medium
                    py-3
                    whitespace-no-wrap
                  "
                >
                  {{ product.price | formatPrice }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { GET_LOCAL_DB_DATA } from "../../browser-db-config/localStorage";
import { eventBus } from "./../../main";
import { permissionsModules } from "./../settings/permission";

export default {
  name: "CategoriesList",
  data() {
    return {
      isLoading: false,
      category: {},
      categoryDeleted: false,
      products: null,
      deleteModal: false,
      permissionsModules,
    };
  },
  computed: {
    productsUnderCategory() {
      return this.sortedProducts.filter(
        (product) => product.category === this.category.name
      );
    },
    sortedProducts() {
      return this.products.sort((a, b) => (a.title > b.title ? 1 : -1));
    },
  },
  async created() {
    this.isLoading = true;
    this.category = GET_LOCAL_DB_DATA("category-detail");
    await this.fetchAllProducts();
    this.isLoading = false;
  },
  methods: {
    showDeleteModal() {
      this.deleteModal = true;
    },
    async deleteCategory() {
      this.isLoading = true;
      const payload = {
        name: this.category.name,
      };
      await this.$store.dispatch("DELETE_CATEGORY", payload);

      const delFeedback = this.$store.getters["GET_RES_FEEDBACK"];
      // Set feedback back to null for future operations
      this.$store.commit("SETUP_FEEDBACK", null);

      if (delFeedback.status) {
        this.categoryDeleted = true;
        setTimeout(() => {
          this.$router.back();
        }, 2000);
      } else {
        // alert(delFeedback.message);
        eventBus.$emit("trac-alert", { message: delFeedback.message });
      }
      this.isLoading = false;
    },
    gotoEditCategory() {
      this.$router.push({ name: "AddCategories" });
    },
    async fetchAllProducts() {
      await this.$store.dispatch("FETCH_ALL_PRODUCTS");
      const res = this.$store.getters["GET_ALL_PRODUCT"];

      if (res.status) {
        this.products = res.items || [];
      } else {
        // alert(res.message || "Error: Error with network.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Error with network.",
        });
      }
    },
  },
};
</script>

<style scoped></style>
